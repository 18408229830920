import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Message
} from 'element-ui'


Vue.use(VueRouter)


const routes = [
  {
    path:"/",
    redirect :"/login"
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/success/:id',
    name: 'PaySuccess',
    component: () => import('../views/pay/PaySuccessfulPage.vue')
  },
  {
    path: '/failed/:id/:price',
    name: 'PayFailed',
    component: () => import('../views/pay/PayFailedPage.vue')
  },
  {
    path: '/pay/:id/:price',
    name: 'pay',
    component: () => import('../views/pay/Payment.vue'),
  },
  {
    path: '/register/:id?/:agentId?/:teamId?',
    name: 'Register',
    component: () => import('../views/login/Register.vue')
  },
  {
    path: '/homeIndex',
    name: 'homeIndex',
    component: ()=> import('../index/index.vue')
  },
  {
    path: '/personalReport/:id?/:ak?/:rk?',
    // path: '/personalReport/:id?/:rk?',//个人（租赁 家政）报告查询模版
    name: 'personalReport',
    component: () => import('@/report/personal-report/index.vue')
  },
  {
    path: '/enterpriseReport',//企业报告查询模版
    name: 'enterpriseReport',
    component: () => import('@/report/enterprise-report/index.vue')
  },
  // {
  //   path: '/housekeepingReport',//家政报告
  //   name: 'housekeepingReport',
  //   component: () => import('@/report/housekeeping-report/index.vue')
  // },
  // {
  //   path: '/rentalReport',//租赁报告
  //   name: 'rentalReport',
  //   component: () => import('@/report/rental-report/index.vue')
  // },
  {
    path: '/test/:id/:skip?',//（个人 租赁  家政） 报告模版
    name: 'test',
    component: () =>  import('@/report-info/personal-info.vue')
  },
  {
    path: '/test2',//（企业）报告模版
    name: 'test2',
    component: () =>  import('@/report-info/enterprise-info.vue')
  },
  {
    path: '/property',//账户资产模块
    name: 'property',
    component: () =>  import('@/views/property/property.vue')
  },
  {
    path: '/personal',//个人中心
    name: 'personal',
    component: () =>  import('@/views/personal-center/personal.vue')
  },
  {
    path: '/bottom',//导航组件
    name: 'bottom',
    component: () =>  import('@/component/BottomNav.vue')
  },
  {
    path: '/userInfo',//个人信息修改
    name: 'userInfo',
    component: () =>  import('@/views/personal-center/user-info.vue')
  },
  {
    path: '/contact-us',//联系我们
    name: 'ContactUs',
    component: () => import('@/views/personal-center/contact-us.vue')
  },
  {
    path: '/Invite-friends',//邀请好友
    name: 'Invite-friends',
    component: () => import('@/index/Invite-friends.vue')
  },
  {
    path: '/team',//我的团队
    name: 'team',
    component: () => import('@/views/personal-center/team/my-team.vue')
  },
  {
    path: '/agent',//我的代理
    name: 'agent',
    component: () => import('@/views/agent/my-agent.vue')
  },
  {
    path: '/VersionTemplatePersonal',//报告示例模板
    name: 'VersionTemplatePersonal',
    component: () => import('@/views/personal-center/report-version/VersionTemplatePersonal.vue')
  },
  {
    path: '/personTemplate',//报告示例模板内容示例
    name: 'personTemplate',
    component: () => import('@/component/template/person-template.vue')
  },
  {
    path: '/updateName',//修改用户名
    name: 'updateName',
    component: () => import('@/views/personal-center/update-name.vue')
  },
  {
    path: '/updatePassword',//修改密码
    name: 'updatePassword',
    component: () => import('@/views/personal-center/update-password.vue')
  },
  {
    path: '/incomeDetails',//收入明细
    name: 'incomeDetails',
    component: () => import('@/views/property/amount-money/IncomeDetails.vue')
  },
  {
    path: '/withdrawalRecord',//提现记录
    name: 'withdrawalRecord',
    component: () => import('@/views/property/amount-money/WithdrawalRecord.vue')
  },
  {
    path: '/Withdrawal',//立即提现
    name: 'Withdrawal',
    component: () => import('@/views/property/amount-money/Withdrawal.vue')
  },
  {
    path: '/myReport',//首页我的报告
    name: 'myReport',
    component: () => import('@/my-report/myReport.vue')
  },
  {
    path: '/historyReport',//历史报告
    name: 'historyReport',
    component: () => import('@/historyReportdeiles/history-report.vue')
  },
  {
    path: '/service',//平台信息技术服务合同
    name: 'service',
    component: () => import('@/views/login/doc/service.vue')
  },
  {
    path: '/terms',//用户注册协议
    name: 'terms',
    component: () => import('@/views/login/doc/user.vue')
  },
  {
    path: '/privacy',//隐私方案管理制度
    name: 'privacy',
    component: () => import('@/views/login/doc/privacy.vue')
  }




]

const router = new VueRouter({
  routes
})

// router.beforeEach(function (to, from, next) {
//   // 检查是否为登录或注册页面
//   if (to.path === '/login' || to.path === '/register') {
//     next(); // 放行登录和注册页面
//   } else if (!sessionStorage.getItem("accessToken")) {
//     // 只有当目标路径不是 /login 时才重定向
//     if (to.path !== '/login') {
//       new Message(
//           {
//             message: '请登录后再操作',
//             type: 'warning',
//             duration: 2000,
//             customClass: 'element-error-message-zindex'
//           }
//       )
//       router.push('/login').catch(err => err); // 如果没有 accessToken，则跳转到登录页面
//     } else {
//       next(); // 如果目标路径已经是 /login，则直接放行
//     }
//   } else {
//     next(); // 其他页面需要 accessToken 才能访问
//   }
// })

export default router
